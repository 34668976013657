<template>
  <div>
    <v-card v-if="!show_tag_management" flat class="pt-4 maxWidth-1100 ma-auto">
      <v-alert
          v-if="stripePaymentOn && subscription.has_trial_period && !subscription.hideBanner && subscription.trial_days_left <=14"
          color="info"
          dark
          dismissible
          @click="hideBanner"
          class="text-left"
      >
        {{ $t( "signedin.FreeTrialAlert1" ) }} {{subscription.trial_days_left}} {{ $t( "signedin.FreeTrialAlert2" ) }}
      </v-alert>
      <h1 class="shio-section-title">{{ $t( "signflow.Complete" ) }}</h1>
      <v-responsive class="pt-5 px-4">
        <v-tabs class="archive-tabs">
          <v-tab class="tab-title">{{ $t( "signflow.ProcessesTabName1" ) }}</v-tab>
          <v-tab class="tab-title">{{ $t( "signflow.ProcessesTabName2" ) }}</v-tab>
          <v-tab-item class="text-left">
            <div>
              <v-row no-gutters class="search-process-area">
                <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t( 'signflow.SearchSignFlows' )"
                              @keydown="onSearchChange()"/>
              </v-row>
              <v-select class="tag-filter search-process-area" v-if="tag_filters.length" v-model="selected_tag_filters" :items="tag_filters" chips :label="$t( 'signflow.FilterByTag' )" multiple solo @change="resetSignFlows()"></v-select>
              <v-radio-group class="search-process-area" v-model="filter" @change="resetSignFlows()">
                <v-row no-gutters>
                  <v-col cols="12" sm="4">
                    <v-radio value="all" :label="$t( 'signflow.AllFlows' )"/>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-radio value="completed" :label="$t( 'signflow.CompletedFlows' )"/>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-radio value="canceled" :label="$t( 'signflow.CanceledFlows' )"/>
                  </v-col>
                </v-row>
              </v-radio-group>
              <v-row class="search-process-area" no-gutters v-if="is_admin" align="top">
                <v-col cols="12">
                  <v-checkbox class="ma-0" :ripple="false" v-model="all_in_group" :label="$t( 'signflow.ShowGroupProcesses' )" @change="resetSignFlows()"/>
                </v-col>
              </v-row>
              <v-expansion-panels focusable>
                <sh-completed-flow class="my-2" v-for="flow in sign_flows" :key="flow.uuid" :flow="flow" @change="resetSignFlows()" @manageTags="showTagsManagement"/>
              </v-expansion-panels>
              <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
                <div slot="no-more"></div>
                <div slot="no-results"></div>
                <v-progress-circular slot="spinner" size="48" indeterminate/>
              </infinite-loading>
              <div v-if="!loading && ( sign_flows.length === 0 )" class="sh-no-processes-message">
                <v-icon>mdi-magnify</v-icon>
                {{ $t( "signflow.NoProcessesFound" ) }}
              </div>
            </div>
          </v-tab-item>
          <v-tab-item class="text-left">
            <v-row class="search-process-area" no-gutters>
              <v-text-field v-model="invitedSearch" append-icon="mdi-magnify" :label="$t( 'signflow.SearchSignFlows' )"
                            @keydown="onInvitedSearchChange()"/>
            </v-row>
            <v-row no-gutters>
              {{ $t( "signflow.ShowOnly12MonthInfo" ) }}
            </v-row>
            <v-expansion-panels focusable>
              <sh-completed-flow class="my-2" v-for="flow in invited_sign_flows" :key="flow.uuid" :flow="flow" :isSigningInvitation="true"/>
            </v-expansion-panels>
            <infinite-loading
                v-if="!stripePaymentOn || subscription.has_trial_period || subscription.has_valid_subscription || subscription.has_payment_past_due || subscription.incomplete_subscription"
                :identifier="infiniteIdForInvited" @infinite="infiniteHandlerForInvited">
              <div slot="no-more"></div>
              <div slot="no-results"></div>
              <v-progress-circular slot="spinner" size="48" indeterminate/>
            </infinite-loading>
            <div v-if="!loading && ( invited_sign_flows.length === 0 ) && ( invitedSearch.length !== 0 )" class="sh-no-processes-message">
              <v-icon>mdi-magnify</v-icon> {{ $t( "signflow.NoProcessesFound" ) }}
            </div>
          </v-tab-item>
        </v-tabs>
      </v-responsive>
    </v-card>
    <v-card v-if="show_tag_management" flat class="pt-4 maxWidth-1100 ma-auto">
      <sh-tags-management @close="closeTagsManagement" v-bind:flow="selected_flow" v-bind:tags="tags"></sh-tags-management>
    </v-card>
  </div>
</template>

<script>
    import _CompletedFlowCard from "./_CompletedFlowCard";
    import _TagsManagementPane from "./_TagsManagementPane";
    import {globalEvents} from "@cloudlace/client";
    import util from "../../../../util/util";
    import InfiniteLoading from 'vue-infinite-loading';
    const paginationLimit = 100;

    let onServerMessage;
    export default {
        name : "start-flow",
        components : {
            "sh-completed-flow" : _CompletedFlowCard,
            "sh-tags-management" : _TagsManagementPane,
            InfiniteLoading
        },
        data()
        {
            return {
                search : "",
                filter : "all",
                all_in_group : false,
                sign_flows : [],
                invited_sign_flows : [],
                is_admin : util.getIsAdmin(),
                groupId : $cc_user_info.organization.uuid,
                from : 0,
                infiniteId : 0,
                loading : true,
                show_tag_management : false,
                selected_flow : {},
                tags : {},
                tag_filters : [],
                selected_tag_filters : [],
                subscription : $cc_user_info.subscription,
                stripePaymentOn : $cc_user_info.stripePaymentOn,
                invitedSearch : "",
                invited_from : 0,
                infiniteIdForInvited : 0,
                invited_loading : true,
            };
        },
        /**
         * Map this.items to state.notes through the Vuex mapState utility.
         */
        computed : {},
        methods : {
            /**
             * Hides subscription time banner.
             */
            hideBanner()
            {
                $cc_user_info.subscription.hideBanner = true;
            },
            /**
             * Search the Signflows from the start, reset the infinite scroll
             */
            resetSignFlows() {
                this.loading = true;
                this.sign_flows = [];
                this.from = 0;
                this.infiniteId ++;
            },
            /**
             * Search the invited Signflows from the start, reset the invited infinite scroll
             */
            resetInvitedSignFlows()
            {
                this.invited_loading = true;
                this.invited_sign_flows = [];
                this.invited_from = 0;
                this.infiniteIdForInvited ++;
            },
            /**
             * Move timeout for refreshing list with new search.
             */
            onSearchChange()
            {
                if( this._searchTimeout )
                {
                    clearTimeout( this._searchTimeout );
                }
                this._searchTimeout = setTimeout( () => this.resetSignFlows(), 500 );
            },
            /**
             * Move timeout for refreshing list with new invited search.
             */
            onInvitedSearchChange()
            {
                if( this._searchTimeout )
                {
                    clearTimeout( this._searchTimeout );
                }
                this._searchTimeout = setTimeout( () => this.resetInvitedSignFlows(), 500 );
            },
            /**
             * Retrieve sign flows matching user's search criteria. Implementing infinite scroll
             */
            infiniteHandler( $state )
            {
                this.loading = true;
                const props = {
                    completed : true,
                    orderBy : "toc",
                    limit : paginationLimit,
                    from : this.from,
                };
                if( this.filter === "completed" )
                {
                    props.canceled = false;
                }
                else if( this.filter === "canceled" )
                {
                    props.canceled = true;
                }
                if( this.search )
                {
                    props.search = this.search;
                }
                if( this.selected_tag_filters.length )
                {
                    props.tags = this.selected_tag_filters;
                }
                const currentFrom = this.from;
                this.$store.signFlowClient.retrieveSignFlows( this.groupId,
                this.all_in_group ? "group" : "user",
                props ).then( r =>
                {
                    if( !currentFrom ) { this.sign_flows = []; }
                    if ( r.signFlows.length )
                    {
                        this.sign_flows.push( ...r.signFlows );
                        this.loading = false;
                        if ( r.signFlows.length < paginationLimit )
                        {
                            $state.complete();
                        }
                        else
                        {
                            this.from = currentFrom + paginationLimit;
                            $state.loaded();
                        }
                    }
                    else
                    {
                        this.loading = false;
                        $state.complete();
                    }
                } ).catch( e => this.$error( this.$t( "signflow.Error!Unexpected" ) ) );
            },
            /**
             * Retrieve sign flows matching user's invited search criteria. Implementing infinite scroll for invited signflows.
             *
             * @param $state
             */
            infiniteHandlerForInvited( $state )
            {
                this.invited_loading = true;
                const props = {
                    completed : true,
                    orderBy : "toc",
                    limit : paginationLimit,
                    from : this.invited_from,
                };
                if( this.invitedSearch )
                {
                    props.search = this.invitedSearch;
                }
                const currentFrom = this.invited_from;
                this.$store.signFlowClient.retrieveSignFlows( this.groupId, "invitations", props ).then( r =>
                {
                    if( !currentFrom ) { this.invited_sign_flows = []; }
                    if ( r.signFlows.length )
                    {
                        this.invited_sign_flows.push( ...r.signFlows );
                        this.invited_loading = false;
                        if ( r.signFlows.length < paginationLimit )
                        {
                            $state.complete();
                        }
                        else
                        {
                            this.invited_from = currentFrom + paginationLimit;
                            $state.loaded();
                        }
                    }
                    else
                    {
                        this.invited_loading = false;
                        $state.complete();
                    }
                } ).catch( e => this.$error( this.$t( "signflow.Error!Unexpected" ) ) );
            },
            /**
             * Retrieve tags information and show the tag management UI.
             */
            showTagsManagement( flow )
            {
                this.$store.signFlowClient.retrieveTags( flow.groupId, flow.uuid, this.is_admin ? "group" : "user" ).then( r=>{
                    this.tags = r.tags;
                    this.show_tag_management = true;
                    this.selected_flow = flow;
                }).catch( e => this.$error( this.$t( "signflow.Error!Unexpected" ) ) );
            },
            /**
             * Close tag management UI.
             */
            closeTagsManagement()
            {
                this.show_tag_management = false;
                this.$store.signFlowClient.retrieveFilterTags( this.groupId,this.is_admin ? "group" : "user" ).then( r=>{
                    this.tag_filters = r.tags.sort( ( a, b ) => a.localeCompare( b, undefined, { caseFirst: "upper" } ) );
                    if( this.selected_tag_filters.length )
                    {
                        const updatedSTF = [];
                        this.selected_tag_filters.forEach( tag => {
                            if( this.tag_filters.includes( tag ) )
                            {
                                updatedSTF.push( tag );
                            }
                        } );
                        if( updatedSTF.length !== this.selected_tag_filters.length )
                        {
                            this.selected_tag_filters = updatedSTF;
                            this.resetSignFlows();
                        }
                    }
                } ).catch( e => this.$error( this.$t( "signflow.Error!Unexpected" ) ) );
            },
            /**
             * Retrieve all the available tags for tag filter list.
             */
            _retrieveFilterTags()
            {
                this.$store.signFlowClient.retrieveFilterTags( this.groupId,this.is_admin ? "group" : "user" ).then( r=>{
                    this.tag_filters = r.tags.sort( ( a, b ) => a.localeCompare( b, undefined, { caseFirst: "upper" } ) );
                } ).catch( e => this.$error( this.$t( "signflow.Error!Unexpected" ) ) );
            },
            /**
             * If a sign flow has changed, refresh the data.
             *
             * @param evt
             * @private
             */
            _onServerMessage( evt )
            {
                const msg = evt.detail;
                if( msg.data_type === "sign_flow" )
                {
                    this.resetSignFlows();
                    this.resetInvitedSignFlows();
                    this._retrieveFilterTags();
                }
            }
        },
        /**
         * Listen to signflow notifications.
         */
        mounted()
        {
            onServerMessage = this._onServerMessage.bind( this );
            globalEvents.on( "shio-signflow-notification", onServerMessage );
            this._retrieveFilterTags();
        },
        /**
         * Remove event listener set in mounted() hook.
         */
        beforeDestroy()
        {
            globalEvents.remove( "shio-signflow-notification", onServerMessage );
        }
    }
</script>

<style lang="sass" scoped>
  @import "../../../../styles/style"

  .sh-no-processes-message
    font-weight: bold

  .tab-title
    text-transform: none
    font-size: 20px
    color: $text-black !important
    letter-spacing: normal

  .v-tab.v-tab--active
    color: $vivid-pink !important

  .v-item-group.v-expansion-panels.v-expansion-panels--focusable,
  .v-input.tag-filter.v-text-field.v-select
    padding: 2px
</style>

<style lang="sass">
  .tag-filter .v-input__slot
    margin-bottom: 0
</style>
