<template>
  <v-container class="pt-4 maxWidth-1100">
    <v-overlay v-if="loading || is_working">
      <v-progress-circular size="48" indeterminate/>
    </v-overlay>
    <infinite-loading :identifier="transactionInfiniteId" @infinite="transactionInfiniteHandler">
      <div slot="no-more"></div>
      <div slot="no-results"></div>
      <v-progress-circular slot="spinner" size="48" indeterminate/>
    </infinite-loading>
    <h1 class="shio-section-title">{{ $t( "signedin.MyWallet" ) }}</h1>
    <v-responsive class="pt-5 px-4">
      <h3 class="text-left">{{ $t( "wallet.AvailableBalance" ) }}</h3>
      <div class="text-left wallet-title-div">
        <span> {{ formatMoney( group_balance )}}</span>&nbsp;
      </div>
      <div>
        <v-row class="fill-height" no-gutters>
          <v-col cols="12" sm="6" md="6" class="text-left py-0">
            <button id="topUpBalance" @click.prevent="" style="margin-top: 20px" ref="button">
              <v-btn @click="topUpWallet" class="primary-btn py-3 ma-auto" block :disabled="!has_billing_info || ( stripePaymentOn && ( !subscription.has_trial_period && !subscription.has_valid_subscription && !subscription.has_payment_past_due
                     && !subscription.incomplete_subscription ) || ( stripePaymentOn && subscription.incomplete_subscription && !subscription.default_pm ) )">
                {{ $t( "wallet.TopUp" ) }}
              </v-btn>
            </button>
          </v-col>
        </v-row>
      </div>
      <br/>
      <div>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div v-if="!has_billing_info">
                <v-icon medium class="no-address-alert-icon mr-1">mdi-alert</v-icon>
                <span> {{ $t("wallet.NoteInvalidAddress") }}</span>
              </div>
              <div v-if="has_billing_info">
                <span> {{ $t("wallet.NoteValidAddress") }}</span>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div>
                <div>
                  <v-row class="mt-5 mb-3">
                    <v-col cols="12" sm="6" md="6" class="text-left py-0">
                      <p class="font-weight-bold">{{ $t("signedin.BillingInformation") }}</p>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="text-right py-0">
                      <v-tooltip v-if="has_billing_info && !editing_address" top>
                        <template v-slot:activator="{on, attrs}">
                            <span v-bind="attrs" v-on="on">
                              <a style="display: inline" v-if="has_billing_info && !editing_address"
                                 @click="editAddress()">
                                {{ $t("signedin.Edit") }}
                              </a>
                            </span>
                        </template>
                        <span v-if="has_billing_info && !editing_address">
                            {{ $t("signedin.EditBillingInformation") }}
                          </span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </div>
                <v-row>
                  <v-col cols="12" sm="12" md="12" class="py-0">
                    <v-text-field outlined :rules="[ validateEmail ]" v-model="email"
                                  :disabled="has_billing_info && !editing_address" clearable>
                      <template v-slot:label>
                        {{ $t('signedin.CreditCardEmail') }}<span class="red--text"> *</span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <v-text-field class="pt-0 tax-id"
                                  v-model="address_line1"
                                  @change="onTaxIdChange()"
                                  :disabled="has_billing_info && !editing_address"
                                  outlined required>
                      <template v-slot:label>
                        {{ $t('signedin.Street') }}<span class="red--text"> *</span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <v-text-field class="pt-0 tax-id"
                                  v-model="address_line2"
                                  :label="$t( 'signedin.Street2' )"
                                  @change="onTaxIdChange()"
                                  :disabled="has_billing_info && !editing_address"
                                  outlined required>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <v-text-field class="pt-0 tax-id"
                                  v-model="address_city"
                                  @change="onTaxIdChange()"
                                  :disabled="has_billing_info && !editing_address"
                                  outlined required>
                      <template v-slot:label>
                        {{ $t('signedin.City') }}<span class="red--text"> *</span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <v-text-field class="pt-0 tax-id"
                                  v-model="address_postal_code"
                                  @change="onTaxIdChange()"
                                  :disabled="has_billing_info && !editing_address"
                                  outlined required>
                      <template v-slot:label>
                        {{ $t('signedin.Zip') }}<span class="red--text"> *</span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="py-0 mb-xs-4">
                    <v-select class="select-country" v-model="address_country"
                              @change="prefillTaxTypes()"
                              :items="countriesJson"
                              item-value="code"
                              :disabled="has_billing_info && !editing_address"
                              outlined
                              required>
                      <template v-slot:label>
                        {{ $t('signedin.SelectCountry') }}<span class="red--text"> *</span>
                      </template>
                      <template v-slot:item="{ item }">
                        {{ $t("signedin.Country!" + item.country) }}
                      </template>
                      <template v-slot:selection="{ item, index }">
                        {{ $t("signedin.Country!" + item.country) }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-0 mb-xs-6">
                    <v-select class="select-country" v-model="preferred_locales"
                              :items="localesList"
                              item-text="name"
                              item-value="code"
                              :disabled="has_billing_info && !editing_address"
                              outlined
                              required>
                      <template v-slot:label>
                        {{ $t('signedin.SelectLocale') }}<span class="red--text"> *</span>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row class="mt-0 mb-4">
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <v-tooltip bottom :disabled="consumers_supported">
                      <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              <v-checkbox class="mt-0" :ripple="false"
                                          v-model="is_business_account"
                                          @change="onBusinessAccountChange()"
                                          :disabled="!consumers_supported || has_billing_info && !editing_address"
                                          :label="$t( 'signedin.BusinessAccount' )"/>
                            </span>
                      </template>
                      <span>{{ $t('signedin.OnlyBusinessAccountsSupported') }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <div class="tax-info-explanation mb-6"
                     v-if="is_business_account && ( !has_billing_info || editing_address )">
                  <p class="text-left">{{ $t("signedin.TaxIdExplanation") }}</p>
                </div>
                <v-row v-if="is_business_account">
                  <v-col cols="12" sm="6" md="6" class="py-0 my-xs-4">
                    <v-select class="select-tax-id-type" v-model="tax_type"
                              :items="sortedTaxTypes"
                              item-value="enum"
                              :disabled="has_billing_info && !editing_address"
                              outlined required>
                      <template v-slot:label>
                        {{ $t('signedin.SelectIdType') }}<span class="red--text"> *</span>
                      </template>
                      <template v-slot:item="{ item }">
                        {{ $t("signedin.TaxType!" + item.enum) }}
                      </template>
                      <template v-slot:selection="{ item, index }">
                        {{ $t("signedin.TaxType!" + item.enum) }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <v-text-field class="pt-0 tax-id"
                                  v-model="tax_id"
                                  @change="onTaxIdChange()"
                                  :disabled="has_billing_info && !editing_address"
                                  outlined required>
                      <template v-slot:label>
                        {{ $t('signedin.TaxId') }}<span class="red--text"> *</span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>

              </div>
              <div class="mb-4">
                <v-row no-gutters>
                  <v-col cols="12" sm="6" md="6" lg="6" xl="4" class="py-3 text-left mobile-center">
                    <button v-if="has_billing_info && editing_address" id="cancelEditBtn" @click.prevent="">
                      <v-btn @click="cancelEditBillingInfo" class="secondary-btn ma-auto" block>
                        {{ $t('signedin.Cancel') }}
                      </v-btn>
                    </button>
                  </v-col>
                  <v-col class="hidden-lg-and-down" cols="4">
                    <v-spacer/>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6" xl="4" class="py-3 text-right mobile-center">
                    <v-tooltip bottom :disabled="validateBillingInfoInputs">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                        <button class="save-billing-btn" v-if="!has_billing_info || editing_address"
                                id="addBillingInfoBtn"
                                @click.prevent="">
                          <v-btn block @click="saveBillingInformation()"
                                 :disabled="!validateBillingInfoInputs || isBillingInfoOrig"
                                 class="primary-btn ma-auto">{{ $t("signedin.SaveBillingInfo") }}</v-btn>
                        </button>
                        </span>
                      </template>
                      <span>{{ $t("signedin.AddBillingTooltip") }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </div>

            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <br/>
      <br/>
      <h3 class="text-left">{{ $t( "wallet.Transactions" ) }}</h3>
      <div>
        <v-row>
          <v-col cols="12" xs="12" sm="3" md="3" lg="2">
            <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    :value="formatFromDate"
                    :label="$t( 'signedin.From' )"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on">
                </v-text-field>
              </template>
              <v-date-picker
                  class="from-date ma-auto"
                  v-model="from_date"
                  :locale="fullLocale"
                  :first-day-of-week="1"
                  @input="resetTransactions();"
                  :max="new Date().toISOString().substr(0, 10)"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" xs="12" sm="3" md="3" lg="2">
            <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    :value="formatToDate"
                    :label="$t( 'signedin.To' )"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  class="to-date ma-auto"
                  v-model="to_date"
                  :locale="fullLocale"
                  :first-day-of-week="1"
                  @input="resetTransactions();"
                  :max="new Date().toISOString().substr(0, 10)"
                  :min="from_date"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" xs="12" sm="4" md="4" lg="3" style="margin-top: 18px">
            <v-autocomplete
                v-model="selected_transaction_titles"
                :items="localizedTransactionTypes"
                dense
                chips
                small-chips
                return-object
                :label="$t( 'wallet.SelectTransactionTypes' )"
                @change="resetTransactions()"
                multiple
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" xs="12" sm="6" md="6" lg="4">
            <v-text-field v-model="transaction_search" append-icon="mdi-magnify" :label="$t( 'wallet.SearchTransactions' )"
                          @change="resetTransactions()"/>
          </v-col>
          <v-col cols="12" xs="1" sm="1" md="1" lg="1">
            <div class="text-left mx-4 mt-sm-6 mt-md-6 mt-lg-6">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon class="download-icon" medium color="black darken-4"
                            @click="downloadTransactions" :disabled="wallet_events.length === 0">mdi-download</v-icon>
                  </span>
                </template>
                <span>{{ $t( "wallet.DownloadTransactionDesc" ) }}</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </div>
      <div>
        <v-simple-table class="transactions-table">
          <template v-slot:default>
            <thead>
            <tr class="text-left">
              <th>
                {{ $t( "wallet.TransactionDate" ) }}
              </th>
              <th>
                {{ $t( "wallet.TransactionTitle" ) }}
              </th>
              <th class="text-right">
                {{ $t( "wallet.TransactionAmount" ) }}
              </th>
              <th class="text-right">
                {{ $t( "wallet.TransactionBalance" ) }}
              </th>
              <th class="text-right">
                {{ $t( "wallet.TransactionInvoice" ) }}
              </th>
            </tr>
            </thead>
            <tbody class="text-left">
            <tr v-for="event in wallet_events" class="transactions-table-tr">
              <td>{{ dateFormat( event.timestamp, "dd.mm.yyyy, HH:MM" ) }}</td>
              <td>
                <a @click.prevent="toggleTransactionDetailsDialog( event )" target="_blank" class="underline-link">
                  {{ $t( "wallet.TransactionTitle!" + event.transaction_title ) }}
                </a>
              </td>
              <td style="text-align: right">{{ formatMoney( event.credit - event.debit ) }}</td>
              <td style="text-align: right">{{ formatMoney( event.balance ) }}</td>
              <td style="text-align: right">
                <a v-if="event.transaction_title === 'top-up-online-payment' || event.transaction_title === 'top-up-bank-transfer'"
                   class="underline-link"
                   @click="downloadInvoiceFile( event )" target="_blank">
                  {{ $t( "wallet.InvoicePDF" ) }}
                </a>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-responsive>
    <v-dialog v-model="top_up_dialog" width="900px" >
      <v-card>
        <v-card-title>
          <div class="float-start">
            <b>{{ $t( "wallet.ChooseRechargeMethod" ) }}</b>
          </div>
          <v-spacer/>
          <div class="float-end">
            <v-icon @click="closeTopUpDialog()">mdi-close</v-icon>
          </div>
        </v-card-title>
        <v-card-text style="min-height: 50vh;">
          <v-divider class="ma-2"/>
          <v-responsive class="pt-5 px-4">
            <v-tabs v-model="tab_key" @change="onTopUpTabChange()">
              <v-tab class="tab-title" value="bank_transfer">{{ $t( "wallet.BankTransfer" ) }}
                <v-tooltip bottom v-if="incomplete_bank_transfer !== null">
                  <template v-slot:activator="{on, attrs}">
                    <v-icon style="font-size: 20px;" class="pl-1" v-bind="attrs" v-on="on">mdi-alert-circle</v-icon>
                  </template>
                  <span>{{ $t( "wallet.PleaseCompleteBankTransfer" ) }}</span>
                </v-tooltip>
              </v-tab>
              <v-tab class="tab-title" value="online_payment">{{ $t( "wallet.OnlinePayment" ) }}
                <v-tooltip bottom v-if="incomplete_online_payment !== null">
                  <template v-slot:activator="{on, attrs}">
                    <v-icon style="font-size: 20px;" class="pl-1" v-bind="attrs" v-on="on">mdi-alert-circle</v-icon>
                  </template>
                  <span>{{ $t( "wallet.PleaseCompleteOnlinePayment" ) }}</span>
                </v-tooltip>
              </v-tab>
              <v-tab class="tab-title">{{ $t( "wallet.TopUpInfo" ) }}</v-tab>
              <v-tab-item class="text-left">
                <div class="topUpInfoContainer pt-4" v-if="incomplete_bank_transfer === null">
                  <p class="text-left font-weight-bold">{{ $t( "wallet.AvailableBalanceTitle" ) }} {{ formatMoney( group_balance )}}</p>
                  <p class="text-left font-weight-bold" v-if="available_cash_balance > 0">
                    {{ availableCashBalanceNotice }}
                  </p>
                  <p class="text-left">{{ bankTransferTopUpAmountTitle }}</p>
                  <v-text-field v-model="bankTransferTopUpValue" style="margin-left: -25px" type="number">
                    <template v-slot:label>
                      {{ $t( "wallet.EnterANumber" ) }}<span class="red&#45;&#45;text"> *</span>
                    </template>
                  </v-text-field>
                </div>
                <div class="topUpInfoContainer pt-4" v-if="incomplete_bank_transfer !== null">
                  <div class="text-left wallet-title-div">{{ $t( 'wallet.PAYMENT' ) }}<span>
                    {{ formatMoney( incomplete_bank_transfer.amount )}}</span>&nbsp;
                    <div :class="incomplete_bank_transfer?.status" class="payment-status-div">
                      {{  $t( 'wallet.paymentStatus!' + incomplete_bank_transfer.status ) }}
                    </div>
                  </div>
                  <p class="text-left mt-4" v-if="incomplete_bank_transfer?.status === 'partially_paid'">
                    {{ this.partiallyPaidDescription }}
                  </p>
                  <p class="text-left">
                    {{ $t( "wallet.BankTransferDesc" ) }}
                  </p>
                  <div class="text-left" style="border: 1px solid grey; padding: 10px">
                    <v-icon>mdi-bank</v-icon>{{ $t( "wallet.IBAN" ) }}
                    <v-row class="fill-height" no-gutters>
                      <v-col cols="12" sm="2" md="2" class="text-left py-0">
                        <p class="text-left mt-2">{{ $t( "wallet.AccountHolder" ) }}</p>
                        <p class="text-left">{{ $t( "wallet.BICCode" ) }}</p>
                        <p class="text-left">{{ $t( "wallet.IBAN" ) }}</p>
                        <p class="text-left">{{ $t( "wallet.Country" ) }}</p>
                      </v-col>
                      <v-col cols="12" sm="10" md="10" class="text-left py-0">
                        <p class="text-left mt-2">{{ iban.account_holder_name }}</p>
                        <p class="text-left">{{ iban.bic }}</p>
                        <p class="text-left">{{ iban.iban }}</p>
                        <p class="text-left">{{ iban.country }}</p>
                      </v-col>
                    </v-row>
                  </div>
                </div>
                <div class="text-right">
                  <v-row>
                    <v-col cols="12" sm="6" md="6" class="text-left">
                      <button @click.prevent="">
                        <v-btn block v-if="incomplete_bank_transfer !== null"
                               @click="deletePaymentIntent( top_up_method, incomplete_bank_transfer?.id )"
                               class="secondary-btn py-5 ma-auto">{{ $t( "wallet.CancelPayment" ) }}</v-btn>
                      </button>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="text-right">
                      <button @click.prevent="">
                        <v-btn block v-if="incomplete_bank_transfer === null"
                               @click="createPaymentIntent()" class="primary-btn py-5 ma-auto"
                               :disabled="!bankTransferTopUpValueValid">{{ $t( "wallet.Proceed" ) }}</v-btn>
                        <v-btn block v-if="incomplete_bank_transfer !== null" @click="closeTopUpDialog()"
                               class="primary-btn py-5 ma-auto">
                          {{ $t( "wallet.Done" ) }}
                        </v-btn>
                      </button>
                    </v-col>
                  </v-row>
                </div>
              </v-tab-item>
              <v-tab-item class="text-left">
                <div class="topUpInfoContainer pt-4" v-if="incomplete_online_payment === null">
                  <p class="text-left font-weight-bold">{{ $t( "wallet.AvailableBalanceTitle" ) }} {{ formatMoney( group_balance )}}</p>
                  <p class="text-left">{{ onlineTopUpAmountTitle }}</p>
                  <v-text-field v-model="onlinePaymentTopUpValue" style="margin-left: -25px" type="number">
                    <template v-slot:label>
                      {{ $t( "wallet.EnterANumber" ) }}<span class="red&#45;&#45;text"> *</span>
                    </template>
                  </v-text-field>
                </div>
                <div class="topUpInfoContainer pt-4" v-if="incomplete_online_payment !== null">
                  <div class="text-left wallet-title-div">{{ $t( 'wallet.PAYMENT' ) }}
                    <span> {{ formatMoney( incomplete_online_payment.amount )}}</span>&nbsp;
                    <div :class="incomplete_online_payment?.status" class="payment-status-div">
                      {{  $t( 'wallet.paymentStatus!' + incomplete_online_payment.status ) }}
                    </div>
                  </div>
                  <div v-if="incomplete_online_payment?.last_payment_error?.code === 'payment_intent_authentication_failure'
                  || incomplete_online_payment?.last_payment_error?.code === 'processing_error'">
                    {{ $t( 'wallet.LastPaymentError' ) }} {{ $t( 'wallet.paymentError!' + incomplete_online_payment.last_payment_error.code ) }}
                  </div>
                  <form id="payment-form" class="mt-4 px-1">
                    <div id="payment-element" ref="payment-element"></div>
                    <div id="payment-message" class="hidden"></div>
                  </form>
                </div>
                <div class="text-right px-2">
                  <v-row>
                    <v-col cols="12" sm="6" md="6" class="text-left">
                      <button @click.prevent="">
                        <v-btn block v-if="incomplete_online_payment !== null"
                               @click="deletePaymentIntent( top_up_method, incomplete_online_payment?.id )"
                               class="secondary-btn py-5 ma-auto">{{ $t( "wallet.CancelPayment" ) }}</v-btn>
                      </button>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="text-right">
                      <button @click.prevent="">
                        <v-btn block v-if="incomplete_online_payment === null"
                               @click="createPaymentIntent()" class="primary-btn py-5 ma-auto"
                               :disabled="!onlinePaymentTopUpValueValid">{{ $t( "wallet.Proceed" ) }}</v-btn>
                        <v-btn block v-if="incomplete_online_payment !== null" @click="confirmPayment()"
                               class="primary-btn py-5 ma-auto">
                          {{ $t( "wallet.PayNow" ) }}
                        </v-btn>
                      </button>
                    </v-col>
                  </v-row>
                </div>
              </v-tab-item>
              <v-tab-item class="text-left top-up-about-text">
                <div class="mt-2">
                  <p>{{ $t("wallet.TopUpExplanation1") }}</p>
                  <p v-html="$t( 'wallet.TopUpExplanation2' )"></p>
                  <p v-html="$t( 'wallet.TopUpExplanationWarn' )"></p>
                  <p v-html="$t( 'wallet.TopUpExplanation3' )"></p>
                  <p>
                    <ul>
                      <li>{{ $t("wallet.TopUpExplanation3Bullet1") }}</li>
                      <li>{{ $t("wallet.TopUpExplanation3Bullet2") }}</li>
                      <li>{{ $t("wallet.TopUpExplanation3Bullet3") }}</li>
                      <li>{{ $t("wallet.TopUpExplanation3Bullet4") }}</li>
                    </ul>
                  </p>
                  <p v-html="$t( 'wallet.TopUpExplanation4' )"></p>
                  <p>
                    <ul>
                      <li>{{ $t("wallet.TopUpExplanation4Bullet1") }}</li>
                      <li>{{ $t("wallet.TopUpExplanation4Bullet2") }}</li>
                      <li>{{ $t("wallet.TopUpExplanation4Bullet3") }}</li>
                    </ul>
                  </p>
                  <p v-html="$t( 'wallet.TopUpExplanation5' )"></p>
                  <p v-html="$t( 'wallet.TopUpExplanation6' )"></p>
                </div>
              </v-tab-item>
            </v-tabs>
          </v-responsive>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="transaction_details_dialog" width="auto">
      <v-card>
        <v-card-title>
          <b>{{ $t( "wallet.TransactionDetails" ) }}</b>
          <v-spacer></v-spacer>
          <v-btn icon @click="toggleTransactionDetailsDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-left" style="min-height: 50vh;min-width: 50vh;">
          <v-divider/>
          <h4 class="font-weight-bold mt-1 mb-1">Title</h4>
          <span>{{ $t( "wallet.TransactionTitle!" + selected_transaction_event.transaction_title ) }}</span>
          <h4 class="font-weight-bold mt-1 mb-1">{{ $t( "wallet.TransactionDescription" ) }}</h4>
          <span v-if="selected_transaction_event.transaction_title === 'top-up-bank-transfer' || selected_transaction_event.transaction_title === 'top-up-online-payment'">
            {{ $t( "wallet.TransactionDes!" + this.selected_transaction_event.transaction_title) }}
            {{ formatMoney( selected_transaction_event.credit - selected_transaction_event.debit ) }}
          </span>
          <span v-if="selected_transaction_event.transaction_title !== 'top-up-bank-transfer' && selected_transaction_event.transaction_title !== 'top-up-online-payment'">
            {{ $t( "wallet.TransactionDes!" + this.selected_transaction_event.transaction_title ) }}
          </span>
          <h4 class="font-weight-bold  mt-1 mb-1">{{ $t( "wallet.Datetime" ) }}</h4>
          <span>{{ dateFormat( selected_transaction_event.timestamp, "dd.mm.yyyy, HH:MM" ) }}</span>
          <h4 class="font-weight-bold mt-1 mb-1">{{ $t( "wallet.Amount" ) }}</h4>
          <span>{{ formatMoney( selected_transaction_event.credit - selected_transaction_event.debit ) }}</span>
          <h4 class="font-weight-bold mt-1 mb-1">{{ $t( "wallet.Balance" ) }}</h4>
          <span>{{ formatMoney( selected_transaction_event.balance ) }}</span>
          <div v-if="selected_transaction_event.transaction_title === 'top-up-online-payment' || selected_transaction_event.transaction_title === 'top-up-bank-transfer'">
            <a class="underline-link"
               @click="downloadInvoiceFile( selected_transaction_event )" target="_blank">
              {{ $t( "wallet.Invoice" ) }}
            </a>
          </div>
          <div v-if="selected_transaction_event.process_uuid && selected_transaction_event.process_uuid !== ''">
            <v-divider class="mt-2 mb-2"/>
            <h4 class="font-weight-bold">{{ $t( "wallet.ProcessUuid" ) }}</h4>
            <span>{{ this.selected_transaction_event.process_uuid }}</span>
            <h4 class="font-weight-bold mt-1 mb-1">{{ $t( "wallet.LastUpdatedBy" ) }}</h4>
            <span>{{ this.selected_transaction_event.username }}</span>
            <h4 class="font-weight-bold mt-1 mb-1">{{ $t( "wallet.Prepayment" ) }}</h4>
            <span>{{ formatMoney( this.selected_transaction_event.prepayment )}}</span>
            <div v-if="selected_transaction_event.transaction_title.indexOf( 'finalise-cost-process' ) !== -1">
              <h4 class="font-weight-bold mt-1 mb-1">{{ $t( "wallet.FinalCost" ) }}</h4>
              <span>{{ formatMoney( this.selected_transaction_event.final_cost ) }}</span>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script src="checkout.js" defer>
</script>
<script src="https://js.stripe.com/v3/"></script>
<script>
    import util from "../../../util/util";
    import dateFormat from "dateformat";
    import {globalEvents} from "@cloudlace/client";
    import InfiniteLoading from 'vue-infinite-loading';
    import countriesJson from '../../../assets/json/StripeCountries.json';
    import localesJson from '../../../assets/json/StripeLocales.json';
    const paginationLimit = 100;
    let onServerMessage;
    const sh_locales = {
        en : "en-US",
        fi : "fi-FI",
        de : "de-DE",
        sv : "sv-SE",
        pl : "pl-PL",
        nl : "nl-NL",
        fr : "fr-FR",
        es : "es-ES",
        th : "th-TH"
    };
    export default {
        name : "WalletPane",
        components : {
            InfiniteLoading
        },
        data() {
            const from = Date.now() - (30 * 24 * 3600 * 1000);
            const to = Date.now();
            return {
                groupId : $cc_user_info.organization.uuid,
                no_row : true,
                tab_key : "",
                top_up_dialog : false,
                transaction_details_dialog : false,
                bankTransferTopUpValue: "",
                onlinePaymentTopUpValue: "",
                wallet_events : [],
                download_wallet_events : [],
                iban : {},
                top_up_method : "bank_transfer",
                client_secret : "",
                group_balance : 0,
                available_cash_balance : 0,
                selected_transaction_event : {},
                from_date : new Date( from ).toISOString().substring( 0, 10 ),
                to_date : new Date( to ).toISOString().substring( 0, 10 ),
                from_menu : false,
                to_menu : false,
                transaction_titles : [ "incoming", "outgoing" ],
                selected_transaction_titles : [ "incoming", "outgoing" ].map( type => ({
                    value: type,
                    text: this.$t("wallet.TransactionType!" + type )
                })),
                loading : true,
                transactionInfiniteId : 0,
                paginationFrom : 0,
                transaction_search : "",
                minOnlineTopUpValue : process.env.VUE_APP_WALLET_MIN_ONLINE_TOP_UP || 10000,
                minBankTransferTopUpValue : process.env.VUE_APP_WALLET_MIN_BANK_TRANSFER_TOP_UP || 100,
                incomplete_online_payment : null,
                incomplete_bank_transfer : null,
                bankTransferTopUpAmountTitle : "",
                onlineTopUpAmountTitle : "",
                partiallyPaidDescription : "",
                availableCashBalanceNotice : "",
                util : util,
                is_working : false,
                consumers_supported : true,
                is_business_account : false,
                email : "",
                tax_id : "",
                tax_type : "",
                sortedTaxTypes : [],
                overWriteTaxId : true,
                original_billing_info : {},
                preferred_locales: "",
                address_country : "",
                address_line1 : "",
                address_line2 : "",
                address_city : "",
                address_state : "",
                address_postal_code : "",
                has_billing_info : false,
                editing_address : false,
                countriesJson: this.sortCountriesByLetter( countriesJson ),
                localesList: this.sortLocalesByLetter( localesJson[ util.getLocale() ] ),
                subscription : $cc_user_info.subscription,
                stripePaymentOn : $cc_user_info.stripePaymentOn,
            }
        },
        methods : {
            /**
             * Date formatter.
             */
            dateFormat : dateFormat,
            /**
             * Validate email.
             */
            validateEmail : util.validateEmail,
            topUpWallet: function()
            {
                this.top_up_dialog = true;
                this.onTopUpTabChange();
            },
            onTopUpTabChange()
            {
                switch( this.tab_key )
                {
                    case 0:
                        this.top_up_method = "bank_transfer";
                        if( this.incomplete_bank_transfer !== null )
                        {
                            this.renderBankInstructions();
                        }
                        else
                        {
                            this.retrieveAvailableCashBalance();
                        }
                        break;
                    case 1:
                        this.top_up_method = "online_payment";
                        if( this.incomplete_online_payment !== null && !this.payment )
                        {
                            this.renderStripeElements();
                        }
                        break;
                    case 2:
                        this.top_up_method = "";
                        break;
                    default:
                        this.top_up_method = "";
                }
            },
            closeTopUpDialog: function()
            {
                this.top_up_dialog = false;
                this.top_up_method = "bank_transfer";
                this.bankTransferTopUpValue = "";
                this.onlinePaymentTopUpValue = "";
            },
            sortEventsByTimestamp( events )
            {
                return events.sort( ( a, b ) => a.timestamp > b.timestamp );
            },
            toggleTransactionDetailsDialog( event )
            {
                if( event )
                {
                    this.selected_transaction_event = event;
                }
                this.transaction_details_dialog = !this.transaction_details_dialog;
            },
            resetTransactions()
            {
                this.loading = true;
                this.wallet_events = [];
                this.paginationFrom = 0;
                this.transactionInfiniteId ++;
            },
            resetBankTransferTopUp()
            {
                this.bankTransferTopUpValue = "";
                this.incomplete_bank_transfer = null;
                this.retrieveAvailableCashBalance();
            },
            resetOnlinePaymentTopUp()
            {
                this.onlinePaymentTopUpValue = "";
                this.incomplete_online_payment = null;
                this.retrieveAvailableCashBalance();
            },
            transactionInfiniteHandler( $state )
            {
                this.loading = true;
                const selected_transaction_title_keys =  this.selected_transaction_titles.map( type => type.value );
                const props = {
                    incoming : selected_transaction_title_keys.includes( "incoming" ),
                    outgoing : selected_transaction_title_keys.includes( "outgoing" ),
                    limit : paginationLimit,
                    paginationFrom : this.paginationFrom,
                    from : this.from,
                    to : this.to,
                };
                if( this.transaction_search.length > 0 )
                {
                    props.transaction_search = this.transaction_search;
                }
                const currentFrom = this.paginationFrom;
                this._retrieveTransaction( props ).then( fetchedWalletEvents =>
                {
                    if( !currentFrom ) { this.wallet_events = []; }
                    if ( fetchedWalletEvents.length > 0 )
                    {
                        this.wallet_events.push( ...fetchedWalletEvents );
                        this.loading = false;
                        if ( fetchedWalletEvents.length < paginationLimit )
                        {
                            $state.complete();
                        }
                        else
                        {
                            this.paginationFrom = currentFrom + paginationLimit;
                            $state.loaded();
                        }
                    }
                    else
                    {
                        this.loading = false;
                        $state.complete();
                    }
                } ).catch( e =>
                {
                    this.$error( this.$t( 'message.Error!Unexpected' ) );
                } );
            },
            base64ToBlob( base64, contentType )
            {
                const byteCharacters = atob( base64 );
                const byteArrays = [];
                for( let offset = 0; offset < byteCharacters.length; offset += 512 )
                {
                    const slice = byteCharacters.slice( offset, offset + 512 );
                    const byteNumbers = new Array( slice.length );
                    for( let i = 0; i < slice.length; i++ )
                    {
                        byteNumbers[ i ] = slice.charCodeAt( i );
                    }
                    const byteArray = new Uint8Array( byteNumbers );
                    byteArrays.push( byteArray );
                }
                return new Blob( byteArrays, { type : contentType } );
            },
            downloadInvoiceFile( event )
            {
                this.$store.shioCoreClient.retrieveWalletInvoice( this.groupId, event.uuid ).then( base64 =>
                {
                    const blob = this.base64ToBlob( base64, 'application/pdf' );
                    const link = document.createElement( 'a' );
                    link.href = window.URL.createObjectURL( blob );
                    link.download = `${event.invoice?.invoiceNum}.pdf`;
                    link.click();
                    window.URL.revokeObjectURL( `${event.invoice?.invoiceNum}.pdf` );
                } ).catch( e =>
                {
                    this.$error( this.$t( 'message.Error!Unexpected' ) );
                } );
            },
            downloadTransactions()
            {
                this.loading = true;
                const selected_transaction_title_keys =  this.selected_transaction_titles.map( type => type.value );
                const props = {
                    incoming : selected_transaction_title_keys.includes( "incoming" ),
                    outgoing : selected_transaction_title_keys.includes( "outgoing" ),
                    limit : paginationLimit,
                    from : this.from,
                    to : this.to,
                };
                if( this.transaction_search.length > 0 )
                {
                    props.transaction_search = this.transaction_search;
                }
                const download_rows = [];
                this._retrieveDownloadTransactions( props ).then( wallet_events =>
                {
                    download_rows.push( this.downloadTransactionsHeaders.map( header => { return header.text} ).join( ',' ) );
                    for( let i = 0; i < wallet_events.length; i++ )
                    {
                        const values = this.downloadTransactionsHeaders.map( header =>
                        {
                            let value = wallet_events[ i ][ header.value ];
                            if( header.value === 'transaction_title' )
                            {
                                value = this.$t("wallet.TransactionTitle!" + value );
                            }
                            if( header.value === 'timestamp' )
                            {
                                value = this.dateFormat( value, "dd.mm.yyyy, HH:MM" );
                            }
                            if( !value && ( header.value === 'username' || header.value === 'process_uuid' ) )
                            {
                                value = "-";
                            }
                            let escaped = ( '' + value ).replace(/"/g, '\\"' );
                            return `"${escaped}"`;
                        });
                        download_rows.push( values.join( ',' ) );
                    }
                    const csvString = download_rows.join( '\n' );
                    const blob = new Blob([ csvString ], { type: 'text/csv' });
                    const url = URL.createObjectURL( blob );
                    const link = document.createElement( 'a' );
                    link.href = url;
                    link.download = this._formulateTransactionFileName();
                    link.click();
                    URL.revokeObjectURL( url );
                    this.loading = false;
                } ).catch( e =>
                {
                    this.$error( this.$t( 'message.Error!Unexpected' ) );
                } )
            },
            _retrieveTransaction( props )
            {
                return new Promise( ( resolve, reject ) =>
                {
                    this.$store.shioCoreClient.retrieveWalletEvents( this.groupId, props ).then( r =>
                    {
                        if ( r.entities.length )
                        {
                            const fetchedWalletEvents = r.entities.map( entity => {
                                entity.entity.debit = entity.entity.debit ? entity.entity.debit : 0;
                                entity.entity.credit = entity.entity.credit ? entity.entity.credit : 0;
                                entity.entity.prepayment = entity.entity.prepayment ? entity.entity.prepayment : 0;
                                entity.entity.balance = entity.entity.balance ? entity.entity.balance : 0;
                                entity.entity.amount = entity.entity.credit -  entity.entity.debit;
                                entity.entity.final_cost = entity.entity.final_cost ? entity.entity.final_cost : "-";
                                return entity.entity;
                            });
                            resolve( fetchedWalletEvents );
                        }
                        else
                        {
                            resolve( [] );
                        }
                    } ).catch( e =>
                    {
                        reject( e );
                    } );
                } );
            },
            _retrieveDownloadTransactions( props )
            {
                return new Promise( ( resolve, reject ) =>
                {
                    this._retrieveTransaction( props ).then( fetchedWalletEvents =>
                    {
                        if( !props.paginationFrom )
                        {
                            props.paginationFrom = 0;
                            this.download_wallet_events = [];
                        }
                        if ( fetchedWalletEvents.length > 0 )
                        {
                            this.download_wallet_events.push( ...fetchedWalletEvents );
                            if ( fetchedWalletEvents.length < paginationLimit )
                            {
                                resolve( this.download_wallet_events );
                            }
                            else
                            {
                                props.paginationFrom = props.paginationFrom + paginationLimit;
                                resolve( this._retrieveDownloadTransactions( props ) );
                            }
                        }
                        else
                        {
                            resolve( this.download_wallet_events );
                        }
                    } ).catch( e =>
                    {
                        this.$error( this.$t( 'message.Error!Unexpected' ) );
                        reject( e );
                    } );
                } );
            },
            _formulateTransactionFileName()
            {
                let fileName = 'transactions';
                this.selected_transaction_titles.map( title => {
                    fileName = fileName + "-" + title.text;
                });
                fileName = fileName + "-" + this.formatFromDate + "-" + this.formatToDate;
                if( this.transaction_search.length > 0 )
                {
                    fileName = fileName + "-" + this.transaction_search;
                }
                return fileName + '.csv';
            },
            retrieveWallet()
            {
                this.$store.shioCoreClient.retrieveWallet( this.groupId ).then( r =>
                {
                    this.group_balance = r.entity.balance;
                } ).catch( e =>
                {
                    this.$error( this.$t( 'message.Error!Unexpected' ) );
                } );
            },
            retrieveAvailableCashBalance()
            {
                this.$store.shioCoreClient.retrieveAvailableCashBalance( this.groupId ).then( r =>
                {
                    this.available_cash_balance = r.available_cash_balance;
                    const availableCashBalance = this.formatMoney( this.available_cash_balance );
                    this.availableCashBalanceNotice = eval( '`' + this.$t( "wallet.AvailableCashBalanceNotice" ) + '`' );
                } ).catch( e =>
                {
                    this.$error( this.$t( 'message.Error!Unexpected' ) );
                } );
            },
            retrieveIncompletePayments()
            {
                this.$store.shioCoreClient.retrieveIncompletePayments( this.groupId ).then( r =>
                {
                    if( r.incomplete_bank_transfer?.status === 'requires_action' )
                    {
                        this.incomplete_bank_transfer = r.incomplete_bank_transfer;
                        this.renderBankInstructions();
                    }
                    if( r.incomplete_online_payment?.status === 'requires_payment_method' )
                    {
                        this.incomplete_online_payment = r.incomplete_online_payment;
                        this.client_secret = r.incomplete_online_payment.client_secret;
                    }
                } ).catch( e =>
                {
                    this.$error( this.$t( 'message.Error!Unexpected' ) );
                } );
            },
            createPaymentIntent()
            {
                if( ( this.top_up_method !== 'bank_transfer' && this.top_up_method !== 'online_payment' ) || !this.has_billing_info )
                {
                    return;
                }
                const topUpValue = ( this.top_up_method === 'bank_transfer' ) ? this.bankTransferTopUpValue : this.onlinePaymentTopUpValue;
                this.$store.shioCoreClient.createPaymentIntent( this.groupId, topUpValue * 100, this.top_up_method ).then( r =>
                {
                    this.client_secret = r.client_secret;
                    if( this.top_up_method === 'online_payment' )
                    {
                        if( r.status !== 'succeeded' )
                        {
                            this.incomplete_online_payment = r;
                            this.renderStripeElements();
                        }
                        else
                        {
                            this.closeTopUpDialog();
                        }
                    }
                    else if( this.top_up_method === 'bank_transfer' )
                    {
                        if( r.status !== 'succeeded' )
                        {
                            this.incomplete_bank_transfer = r;
                            this.renderBankInstructions();
                        }
                        else
                        {
                            this.closeTopUpDialog();
                        }
                    }
                } ).catch( e =>
                {
                    this.$error( this.$t( 'message.Error!Unexpected' ) );
                } );
            },
            deletePaymentIntent( method, paymentIntentId )
            {
                this.$store.shioCoreClient.deletePaymentIntent( this.groupId, paymentIntentId ).then( r =>
                {
                    if( method === 'bank_transfer' )
                    {
                        this.resetBankTransferTopUp();
                    }
                    else if( method === 'online_payment' )
                    {
                        this.resetOnlinePaymentTopUp();
                    }
                } ).catch( e =>
                {
                    this.$error( this.$t( 'message.Error!Unexpected' ) );
                } );

            },
            renderBankInstructions()
            {
                this.iban = this.incomplete_bank_transfer.next_action.display_bank_transfer_instructions.financial_addresses[ 0 ].iban;
                if( this.incomplete_bank_transfer.next_action?.display_bank_transfer_instructions?.financial_addresses[ 0 ]?.iban )
                {
                    this.iban = this.incomplete_bank_transfer.next_action?.display_bank_transfer_instructions?.financial_addresses[ 0 ]?.iban;
                }
                if( this.incomplete_bank_transfer.amount > this.incomplete_bank_transfer.next_action?.display_bank_transfer_instructions?.amount_remaining )
                {
                    this.incomplete_bank_transfer.status = "partially_paid";
                    const amountPaid = this.formatMoney( this.incomplete_bank_transfer.amount - this.incomplete_bank_transfer.next_action?.display_bank_transfer_instructions?.amount_remaining );
                    const amountRemaining = this.formatMoney( this.incomplete_bank_transfer.next_action?.display_bank_transfer_instructions?.amount_remaining );
                    this.partiallyPaidDescription = eval( '`' + this.$t( "wallet.BankTransferPartiallyPaidDesc" ) + '`' );
                }
            },
            renderStripeElements()
            {
                setTimeout( function()
                {
                    if( typeof Stripe === 'undefined' )
                    {
                        this.renderStripeElements();
                    }
                    else
                    {
                        this.stripe = Stripe( process.env.VUE_APP_STRIPE_PUBLIC_KEY );
                        if( this.payment )
                        {
                            this.payment.clear();
                        }
                        const clientSecret = this.client_secret;
                        const appearance = {
                            theme: 'stripe',
                        };
                        this.stripeElements = this.stripe.elements({ appearance, clientSecret });
                        const paymentElementOptions = {
                            layout: "tabs",
                        };
                        this.payment = this.stripeElements.create( "payment", paymentElementOptions );
                        this.payment.mount( "#payment-element" );
                    }
                }.bind( this ), 100 );
            },
            confirmPayment()
            {
                const root = process.env.VUE_APP_MAIN_DOMAIN_ADDRESS;
                const walletPageLink = root + "/#/wallet";
                const elements = this.stripeElements;
                this.stripe.confirmPayment({
                    elements,
                    confirmParams: {
                        return_url:  walletPageLink,
                    },
                }).then( res =>
                {
                    if ( res.error) {
                        // Inform the customer that there was an error.
                        this.is_working = false;
                        this.incomplete_online_payment = res.error.payment_intent;
                        if( this.incomplete_online_payment.last_payment_error.code === 'payment_intent_authentication_failure' )
                        {
                            this.$error( this.$t( 'wallet.paymentError!' + this.incomplete_online_payment.last_payment_error.code ) );
                        }
                        else
                        {
                            this.$error( this.$t( 'message.Error!Unexpected' ) );
                        }
                    }
                }).catch( e =>
                {
                    this.is_working = false;
                    this.$error( this.$t( 'message.Error!Unexpected' ) );
                } );
            },
            _onServerMessage( evt )
            {
                if( evt.detail.data_type === "wallet" )
                {
                    this.retrieveWallet();
                    this.resetTransactions();
                }
            },
            formatMoney( amount )
            {
                if( typeof amount === 'number' )
                {
                    return "€" + ( ( amount / 100 ).toFixed( 2 ) );
                }
                else
                {
                    return amount;
                }
            },
            /**
             * Update tax id overwrite status.
             *
             */
            onTaxIdChange()
            {
                this.overWriteTaxId = !( this.tax_id && this.tax_id.length > 2 );
            },
            onBusinessAccountChange()
            {
                if( !this.is_business_account )
                {
                    this.tax_type = "";
                    this.tax_id = "";
                }
                else
                {
                    this.tax_type = this.sortedTaxTypes[ 0 ].enum;
                    this.tax_id = this.sortedTaxTypes[ 0 ].tax_code;
                }
            },
            prefillTaxTypes()
            {
                let taxTypes = [];
                this.countriesJson.find( country => { if ( country.code === this.address_country )
                {
                    this.consumers_supported = country.consumers_supported;
                    country.tax_types.forEach( type =>
                    {
                        type.code = country.code;
                        type.tax_code = country.tax_code;
                        taxTypes = country.tax_types;
                    } );
                } } );
                taxTypes = taxTypes.sort( ( a, b ) => a.desc.localeCompare( b.desc, undefined, { caseFirst: "upper" } ) );
                if( this.address_country === 'GB' && taxTypes[0].enum !== 'gb_vat' )
                {
                    [ taxTypes[ 0 ], taxTypes[ 1 ] ] = [ taxTypes[ 1 ], taxTypes[ 0 ] ];
                }
                if( this.address_country )
                {
                    this.tax_type = taxTypes[ 0 ].enum;
                    if( this.overWriteTaxId )
                    {
                        this.tax_id = taxTypes[ 0 ].tax_code;
                    }
                    if( !this.consumers_supported )
                    {
                        this.is_business_account = true;
                    }
                }
                this.sortedTaxTypes = taxTypes;
            },
            /**
             * Start to edit address.
             */
            editAddress()
            {
                this.editing_address = true;
            },
            /**
             * Stop editing address and revert billing values.
             */
            cancelEditBillingInfo()
            {
                this.editing_address = false;
                this.email = this.original_billing_info.email;
                this.preferred_locales = this.original_billing_info.preferred_locales;
                this.is_business_account = this.original_billing_info.is_business_account;
                this.tax_id = this.original_billing_info.tax_id;
                this.tax_type = this.original_billing_info.tax_type;
                this.address_country = this.original_billing_info.address_country;
                this.address_line1 = this.original_billing_info.address_line1;
                this.address_line2 = this.original_billing_info.address_line2;
                this.address_postal_code = this.original_billing_info.address_postal_code;
                //this.address_state = this.original_billing_info.address_state;
                this.address_city = this.original_billing_info.address_city;
                const country = this.countriesJson.find( country => country.code === this.address_country );
                this.consumers_supported = country && country.consumers_supported;
            },
            /**
            /**
             *  Sort countries by country name alphabetically.
             *
             *  @param countries {Object[]}
             *  @returns {Object[]}
             */
            sortCountriesByLetter( countries )
            {
                return countries.sort( ( a, b ) => this.$t( "signedin.Country!" + a.country ).localeCompare( this.$t( "signedin.Country!" + b.country ), undefined, { caseFirst: "upper" } ) );
            },
            /**
             *  Sort locales by locale name alphabetically.
             *
             *  @param locales {Object[]}
             *  @returns {Object[]}
             */
            sortLocalesByLetter( locales )
            {
                return locales.sort( ( a, b ) => a.name.localeCompare( b.name, undefined, { caseFirst: "upper" } ) );
            },
            saveBillingInformation()
            {
                this.is_working = true;
                const taxIdChanged = this.is_business_account !== this.original_billing_info.business_account
                                     || this.is_business_account && this.tax_id !== this.original_billing_info.tax_id;
                this.$store.shioCoreClient.updateBillingInformation(
                $cc_user_info.organization.uuid,
                {
                    email : this.email,
                    tax_object_id : taxIdChanged ? this.tax_object_id : "",
                    old_tax_id : taxIdChanged ? this.original_billing_info.tax_id : "",
                    old_tax_type : taxIdChanged ? this.original_billing_info.tax_type : "",
                    tax_type : this.is_business_account ? this.tax_type : "",
                    tax_id : this.is_business_account ? this.tax_id : "",
                    preferred_locales : this.preferred_locales || "",
                },
                {
                    postal_code : this.address_postal_code || "",
                    city : this.address_city || "",
                    line1 : this.address_line1 || "",
                    line2 : this.address_line2 || "",
                    state : this.address_state || "",
                    country : this.address_country || ""
                } ).then( r =>
                {
                    util.retrieveSubscription( this.$store.shioCoreClient,
                    $cc_user_info.organization.display_name ).then( () =>
                    {
                        this.subscription = $cc_user_info.subscription;
                        this.$inform( this.$t( "message.BillingInformationSaved" ) );
                        this.is_working = false;
                        this.has_billing_info = true;
                        this.editing_address = false;
                        const taxObjectData = { value : this.tax_id, type: this.tax_type, id : ( r.length > 0 ) ? r[ 0 ].id : this.tax_object_id };
                        this.updateBillingInfo( this.is_business_account ? taxObjectData : "" );
                    } ).catch( e =>
                    {
                        this.is_working = false;
                        this.$error( this.$t( 'message.Error!Unexpected' ) );
                    } );
                } ).catch( e =>
                {
                    this.is_working = false;
                    if( this.refreshed_tax_object_id )
                    {
                        this.tax_object_id = this.refreshed_tax_object_id;
                    }
                    if( e.code )
                    {
                        this.$error( this.$t( 'message.Error!' + e.code ) );
                    }
                    else
                    {
                        this.$error( this.$t( 'message.Error!Unexpected' ) );
                    }
                } );
            },
            /**
             * Update billing info.
             *
             * @param taxObjectData
             */
            updateBillingInfo( taxObjectData )
            {
                if( this.subscription.preferred_locales &&  this.subscription.preferred_locales.length > 0 )
                {
                    this.preferred_locales = this.subscription.preferred_locales[ 0 ];
                }
                else
                {
                    // Set default locale for locale selector.
                    this.preferred_locales = ( !this.subscription.address )
                    ? sh_locales[ util.getLocale() ] : "en-US";
                }
                if( !this.subscription.address )
                {
                    this.subscription.address = {};
                }
                if( this.subscription.address.country )
                {
                    this.has_billing_info = true;
                }
                this.email = this.subscription.email;
                this.address_country = this.subscription.address.country;
                this.address_postal_code = this.subscription.address.postal_code;
                this.address_city = this.subscription.address.city;
                this.address_line1 = this.subscription.address.line1;
                this.address_line2 = this.subscription.address.line2;
                //this.address_state = this.subscription.address.state;
                this.prefillTaxTypes();
                this.is_business_account = !!taxObjectData;
                this.tax_type = taxObjectData ? taxObjectData.type : "";
                this.tax_id = taxObjectData ? taxObjectData.value : "";
                this.tax_object_id = taxObjectData ? taxObjectData.id : "";
                this.original_billing_info = {
                    email : this.email,
                    preferred_locales : this.preferred_locales,
                    address_country : this.address_country,
                    address_postal_code : this.address_postal_code,
                    address_city : this.address_city,
                    address_line1 : this.address_line1,
                    address_line2 : this.address_line2,
                    //address_state : this.address_state,
                    is_business_account : this.is_business_account,
                    tax_type : this.tax_type,
                    tax_id : this.tax_id,
                    tax_object_id : this.tax_object_id
                };
            },
            /**
             * Retrieve subscription info.
             */
            retrieveMySubscriptionInfo()
            {
                this.$store.shioCoreClient.retrievePaymentMethod( $cc_user_info.organization.uuid ).then( r =>
                {
                    this.updateBillingInfo( r.taxObject.data[ 0 ] );
                    this.is_working = false;
                } ).catch( e =>
                {
                    this.$error( this.$t( 'message.Error!Unexpected' ) );
                } );
            }
        },
        computed : {
            downloadTransactionsHeaders()
            {
                return [ {
                    text: this.$t( 'wallet.Title' ),
                    value: 'transaction_title',
                }, {
                    text: this.$t( 'wallet.Datetime' ),
                    value: 'timestamp'
                }, {
                    text: this.$t( 'wallet.Amount' ),
                    value: 'amount'
                }, {
                    text: this.$t( 'wallet.Balance' ),
                    value: 'balance'
                }, {
                    text: this.$t( 'wallet.LastUpdatedBy' ),
                    value: 'username'
                }, {
                    text: this.$t( 'wallet.ProcessUuid' ),
                    value: 'process_uuid'
                }, {
                    text: this.$t( 'wallet.Prepayment' ),
                    value: 'prepayment'
                }, {
                    text: this.$t( 'wallet.FinalCost' ),
                    value: 'final_cost'
                }];
            },
            localizedTransactionTypes()
            {
                return this.transaction_titles.map( type => ({
                    value: type,
                    text: this.$t("wallet.TransactionType!" + type )
                }));
            },
            bankTransferTopUpValueValid()
            {
                return ( this.bankTransferTopUpValue >= ( this.minBankTransferTopUpValue / 100 ) );
            },
            onlinePaymentTopUpValueValid()
            {
                return ( this.onlinePaymentTopUpValue >= ( this.minOnlineTopUpValue / 100 ) );
            },
            fullLocale()
            {
                switch ( util.getLocale() )
                {
                    case "fi":
                        return "fi-FI";
                    case "sv":
                        return "sv-SE";
                    case "pl":
                        return "pl-PL";
                    case "de":
                        return "de-DE";
                    case "nl":
                        return "nl-NL";
                    case "fr":
                        return "fr-FR";
                    case "es":
                        return "es-ES";
                    case "th":
                        return "th-TH";
                    default:
                        return "en-GB";
                }
            },
            formatFromDate()
            {
                return dateFormat( new Date( this.from_date ), "dd.mm.yyyy" );
            },
            formatToDate()
            {
                return dateFormat( new Date( this.to_date ), "dd.mm.yyyy" );
            },
            from()
            {
                return new Date( this.from_date + 'T00:00:00.000Z' ).getTime();
            },
            to()
            {
                return new Date( this.to_date + 'T23:59:59.999Z' ).getTime();
            },
            isBillingInfoOrig()
            {
                return     ( ( this.email === this.original_billing_info.email )
                             && ( this.preferred_locales === this.original_billing_info.preferred_locales )
                             && ( this.address_line1 === this.original_billing_info.address_line1 )
                             && ( this.address_line2 === this.original_billing_info.address_line2 )
                             && ( this.address_postal_code === this.original_billing_info.address_postal_code )
                             && ( this.address_country === this.original_billing_info.address_country )
                             && ( this.address_city === this.original_billing_info.address_city )
                             //&& ( this.address_state === this.original_billing_info.address_state )
                             && ( this.is_business_account === this.original_billing_info.is_business_account )
                             && ( !this.is_business_account || this.tax_id === this.original_billing_info.tax_id )
                             && ( this.email === this.original_billing_info.email ) );
            },
            validateBillingInfoInputs()
            {
                return   ( ( this.email && this.email.length > 0 )
                           && ( this.address_country && this.address_country.length > 0 )
                           && ( this.address_postal_code && this.address_postal_code.length > 0 )
                           && ( this.address_line1 && this.address_line1.length > 0 )
                           && ( this.address_city && this.address_city.length > 0 )
                           && ( this.preferred_locales && this.preferred_locales.length > 0 )
                           && ( !this.is_business_account || this.tax_id && this.tax_id.length > 2 )
                           && ( !this.is_business_account || ( this.tax_id.substring( 0, 2 ) === 'FI' &&  this.address_country === 'FI' )
                                || ( this.tax_id.substring( 0, 2 ) !== 'FI' &&  this.address_country !== 'FI' ) )
                           && this.validateEmail( this.email ) === true );
            }
        },
        mounted()
        {
            if( window.location.search )
            {
                const url = new URL(window.location.href);
                url.search = ''; // Clear all query parameters
                window.history.replaceState(null, '', url.toString());
            }
            if( typeof Stripe === 'undefined' )
            {
                const stripeScript = document.createElement( "script" );
                stripeScript.setAttribute( "src", process.env.VUE_APP_STRIPE_SCRIPT_URL );
                document.head.appendChild( stripeScript );
            }
            this.retrieveWallet();
            this.retrieveIncompletePayments();
            this.retrieveMySubscriptionInfo();
            this.subscription = $cc_user_info.subscription;
            const bankTransferMinTopUpValue = this.formatMoney( parseInt( this.minBankTransferTopUpValue ) );
            const onlineMinTopUpValue = this.formatMoney( parseInt( this.minOnlineTopUpValue ) );
            this.bankTransferTopUpAmountTitle = eval( '`' + this.$t( "wallet.BankTransferMinTopUpAmount" ) + '`' );
            this.onlineTopUpAmountTitle = eval( '`' + this.$t( "wallet.OnlineMinTopUpAmount" ) + '`' );
            onServerMessage = this._onServerMessage.bind( this );
            globalEvents.on( "shio-signflow-notification", onServerMessage );
        }
    }
</script>

<style lang="sass" scoped>
  @import "../../../styles/style"

  .payment-form
    min-width: 400px
    align-self: center
    border-radius: 6px
    padding: 0

  .v-tab.v-tab--active
    color: $vivid-pink !important

  .tab-title
    text-transform: none
    font-size: 20px
    color: $text-black !important
    letter-spacing: normal

  .inputForm
    border-radius: 6px
    margin-bottom: 12px
    padding: 12px
    border: 1px solid rgba(50, 50, 93, 0.1)
    height: 48px
    font-size: 16px
    width: 100%
    background: white

  .transactions-table th, td
    font-size: 16px !important
    color: $text-black
    font-weight: normal

  .transactions-table-tr td a
    color: $text-black !important

  .v-data-table.transactions-table
    border: 0

  .remove-icon
    font-size: 20px
    color: $vivid-pink
    cursor: pointer

  .underline-link
    text-decoration: underline

  .topUpInfoContainer
    margin-bottom: 0.5rem
    padding: 0px

  .no-address-alert-icon
    font-size: 20px
    color: $vivid-pink !important

  .address-check-icon
    font-size: 20px
    color: green

  .top-up-about-text
    color: $text-black

  @media (max-width: 599px)
    .mobile-center, .cancel-plan-btn
      text-align: center !important

    button
      width: 100%

    button.v-icon.remove-icon
      width: initial !important

    .mb-xs-4
      margin-bottom: 16px

    .mb-xs-6
      margin-bottom: 24px

    .my-xs-4
      margin-top: 16px
      margin-bottom: 16px

  .wallet-title-div
    font-size: 16px
    font-weight: bold
    margin-top: 20px

  .payment-status-div
    display: inline
    padding: 2px 5px 2px 5px
    border-radius: 4px
    font-size: 15px
    font-weight: normal

  .partially_paid
    background-color: #FFDBBB

  .requires_payment_method
    background-color: #f2f2f2

  .requires_action
    background-color: #f2f2f2
</style>

<style lang="sass">
  .v-input.select-tax-id-type input
    max-height: 0
    padding: 0

  @media (max-width: 599px)

    .payment-form
      min-width: auto !important

</style>
