<template>
<!--  <v-card dark class="pa-0 new-user-card">
    <v-row no-gutters align="center">
      <v-col class="sh-user-card-icon">
        <v-row align="center" justify="center" class="fill-height">
          <v-icon size="64" color="white" class="pa-4">mdi-account-circle</v-icon>
        </v-row>
      </v-col>
      <v-col>
        <v-card flat light tile class="sh-orgnization-user-card ma-auto pa-2">
          <v-card-title class="pa-0">
            <v-text-field class="ma-0 pt-2" :rules="[validateFullName]" v-model="name" :label="$t( 'signedin.UserName' )"/>
          </v-card-title>
          <v-responsive aspect-ratio="6">
            <div>
              <v-form v-model="valid">
                <v-text-field class="ma-0 pt-2" :rules="[ validateEmail ]" type="email" v-model="email" :label="$t( 'signedin.Email' )"/>
              </v-form>
            </div>
          </v-responsive>
          <v-card-actions class="pa-0">
            <v-row align="middle" no-gutters>
              <v-col cols="6">
                <v-checkbox class="ma-0" v-model="isAdmin" :label="$t( 'signedin.isAdmin' )"/>
                <v-checkbox v-if="stripePaymentOn" :disabled="isAdmin" class="ma-0 pt-0" v-model="isSubscriptionAdmin" :label="$t( 'signedin.isSubscriptionAdmin' )"/>
              </v-col>
              <v-spacer/>
              <v-col cols="6">
                <v-btn class="org-invite-btn mt-3" color="primary" height="40" depressed
                       :disabled="!ready" @click="inviteUser()">
                  {{ $t( "signedin.InviteUser" )}}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-card>-->

  <v-expansion-panel class="add-user-panel">
    <v-expansion-panel-header>
      <div>
        <v-icon class="ml-0 mr-2 mx-sm-2 mx-md-2 mx-lg-2 mx-xl-2" small color="primary">mdi-account-plus</v-icon>
        <span class="invite-user-header">{{ $t( "signedin.InviteUserHeader" ) }}</span>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="accounts-panel-content">
      <v-card flat>
        <v-responsive>
          <v-row class="mt-3">
            <v-col class="name-text-field" cols="12" sm="12" md="6" lg="6">
              <v-text-field :rules="[ validateFullName ]" v-model="name" :label="$t( 'signedin.UserName' )" outlined>
              </v-text-field>
            </v-col>
            <v-col class="email-text-field" cols="12" sm="12" md="6" lg="6">
              <v-form v-model="valid">
                <v-text-field :rules="[ validateEmail ]" type="email" v-model="email" :label="$t( 'signedin.Email' )"
                              outlined>
                </v-text-field>
              </v-form>
            </v-col>
          </v-row>
          <v-row class="mt-0 mb-1">
            <v-col class="pt-0 admin-checkbox" cols="12" sm="12" md="6" lg="6">
              <v-checkbox class="ma-0" :ripple="false" v-model="isAdmin" :label="$t( 'signedin.isAdmin' )"/>
            </v-col>
            <v-col class="pt-0 subscription-checkbox" cols="12" sm="12" md="6" lg="6">
              <v-checkbox v-if="stripePaymentOn" :disabled="isAdmin" class="ma-0" :ripple="false" v-model="isSubscriptionAdmin"
                          :label="$t( 'signedin.isSubscriptionAdmin' )"/>
            </v-col>
          </v-row>
        </v-responsive>
        <v-divider></v-divider>
        <v-card-actions class="operation-panel">
          <v-row no-gutters>
            <v-col class="text-left operation-panel-buttons">
              <v-btn class="primary-btn" :disabled="!ready" @click="inviteUser()">
                {{ $t( "signedin.InviteUser" ) }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
    import util from "../../../util/util";
    export default {
        data()
        {
            return {
                name : "",
                email : "",
                valid : false,
                isAdmin : false,
                isSubscriptionAdmin : false,
                stripePaymentOn : $cc_user_info.stripePaymentOn
            }
        },
        computed :
        {
            ready()
            {
                return this.valid && this.email && this.name && ( this.validateFullName( this.name ) === true );
            }
        },
        watch : {
            isAdmin : function ( val )
            {
                if ( val ) this.isSubscriptionAdmin = true;
            }
        },
        methods :
        {
            validateEmail : util.validateEmail,
            validateFullName : util.validateFullName,
            /**
             * Create a new user.
             */
            inviteUser()
            {
                util.retrieveMeErrorTimes( this.$store.shioCoreClient ).then( r =>
                {
                    if( r >= 5 )
                    {
                        this.$error( this.$t( "message.Error!too_many_incorrect_email" ) );
                    }
                    else
                    {
                        const pl = {
                            email : this.email,
                            name : this.name,
                            is_admin : this.isAdmin,
                            is_subscription_admin : this.stripePaymentOn ? this.isSubscriptionAdmin : this.isAdmin,
                            locale : util.getLocale(),
                        };
                        this.$store.shioCoreClient.createUser( util.getOrganizationId(), pl ).then( r =>
                        {
                            this.email = "";
                            this.name = "";
                            this.isAdmin = false;
                            this.isSubscriptionAdmin = false;
                            this.$emit( 'user-created', pl );
                            this.$alert( { text : this.$t( "signedin.UserInvited" ), acceptText : this.$t( "signflow.Ok" ) } );
                        } ).catch( e =>
                        {
                            if ( e.code_key === 409 )
                            {
                                const email = "hello@signhero.io";
                                this.$error( eval( "`" + this.$t( "signedin.Error!InvitedUserExists" ) + "`" ) );
                            }
                            else
                            {
                                this.$error( this.$t( "signedin.Error!Unexpected" ) );
                            }
                        }  );
                    }
                } ).catch( e =>
                {
                    this.$error( this.$t( "signedin.Error!Unexpected" ) );
                }  );
            }
        }
    }
</script>

<style lang="sass" scoped>
  @import "../../../styles/style"

  .new-user-card
    background-color: $card-grey

  .sh-orgnization-user-card button.v-btn.v-btn--disabled
    background-color: $vivid-pink !important
    opacity: 0.5
    color: white !important

  .org-invite-btn
    width: 100%
    font-size: 16px

  .org-invite-btn:hover
    background-color: $dark-pink !important

  .accounts-panel-content .v-responsive
    padding-left: 55px
    padding-right: 55px

  @media (max-width: 599px)
    .accounts-panel-content .v-responsive
      padding-left: 35px
      padding-right: 35px
</style>

<style lang="sass">
  .new-user-card .v-text-field__details
    min-height: 0 !important

  @media (max-width: 959px)
    .sh-orgnization-user-card label.v-label
      font-size: 14px

    .name-text-field
      padding-bottom: 0

    .email-text-field
      padding-top: 0
</style>
