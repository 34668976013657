import { render, staticRenderFns } from "./NewUserCard.vue?vue&type=template&id=2dd06980&scoped=true"
import script from "./NewUserCard.vue?vue&type=script&lang=js"
export * from "./NewUserCard.vue?vue&type=script&lang=js"
import style0 from "./NewUserCard.vue?vue&type=style&index=0&id=2dd06980&prod&lang=sass&scoped=true"
import style1 from "./NewUserCard.vue?vue&type=style&index=1&id=2dd06980&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dd06980",
  null
  
)

export default component.exports